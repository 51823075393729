.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
}
// .servicesDescriptionContainer {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
// }

// .servicesDescriptionColumnContainer {
//   display: flex;
//   flex-direction: column;
//   max-width: calc(25% - 32px);
//   margin: 16px;
// }
