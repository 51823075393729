@import "../../_theme.scss";

.highlighted {
  background: $accent-orange;
}

.normal {
  background: white;
}

.menuButton {
  color: $black;
  font-size: 20px;
  border-radius: 1px;
  padding: 6px;
  margin-right: 8px;
  text-align: center;
}

.menuButton a {
  text-decoration: none;
  color: $black;
  font-size: 20px;
  font-weight: 500;
}
