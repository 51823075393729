:root {
  --azul: #0a2e54;
  --negro: #001011;
  --amarillo: #fffd82;
  --component: #eff3f7;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
