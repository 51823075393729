@import "../../../_theme.scss";

.item {
  text-decoration: none;
  margin-bottom: 10px;
  color: $black;
  display: flex;
  width: 80%;
  height: 40px;
  align-items: center;
  font-size: 20px;
  padding: 8px;
}

.item:hover {
  width: 80%;
  background: $accent-orange;
  color: $white;
}

.img {
  width: 50px;
  margin-right: 8px;
}

.itemSelected {
  background: $accent-orange;
  color: $white;
}
