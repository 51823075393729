.tools {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-left: 16px;
}

.hr {
  margin-top: -10px;
  width: 100px;
  margin-left: 22px;
}
