@import "../../_theme.scss";

.textarea {
  max-width: calc(100% - 8px);
  min-width: calc(100% - 8px);
  min-height: 200px;
  background: transparent;
  border: 1px solid $bg-blue;
  border-radius: 2px;
  padding: 4px;
}
