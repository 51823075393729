@import "../../_theme.scss";

.h1 {
  font-size: 2em;
}

.h2 {
  font-size: 1.5em;
}

.h3 {
  font-size: 1.25em;
}

.base {
  text-align: justify;
}

.span {
  font-size: 0.75em;
}

.accent {
  color: $accent-orange;
}
