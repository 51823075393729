.container {
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 8px;
  margin: 8px;
}

.filler {
  height: 100%;
  background-color: #000000;
  border-radius: inherit;
  text-align: right;
  transition: width 0.2s ease-in-out;
  max-width: 100%;
}

.fillerFull {
  background-color: #970000;
}

.label {
  padding: 5px;
  color: white;
  font-weight: bold;
}
