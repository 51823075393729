@import "../../_theme.scss";

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  padding: 2rem 0 1rem;
  list-style: none;
}

.tag {
  display: flex;
  align-items: center;
  margin: 0.1rem 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.colorBase {
  color: $bg-blue;
}

.colorAccent {
  color: $accent-orange;
}
