@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{--azul: #0a2e54;--negro: #001011;--amarillo: #fffd82;--component: #eff3f7}body{margin:0;padding:0;font-family:"Lato", sans-serif}

.Sidebar_sidebar__YZddB{width:20%;height:100vh}

.SideBarMenu_tools__1I0Oz{width:100%;display:flex;flex-direction:column;padding:8px;padding-left:16px}.SideBarMenu_hr__2ELwt{margin-top:-10px;width:100px;margin-left:22px}

.LKTypography_h1__3kpt9{font-size:2em}.LKTypography_h2__Ak2pm{font-size:1.5em}.LKTypography_h3__Mlqaj{font-size:1.25em}.LKTypography_base__1p-LS{text-align:justify}.LKTypography_span__VJBZQ{font-size:0.75em}.LKTypography_accent__2aI46{color:#dfa200}

.SideBarMenuElement_item__6reRt{text-decoration:none;margin-bottom:10px;color:#000;display:flex;width:80%;height:40px;align-items:center;font-size:20px;padding:8px}.SideBarMenuElement_item__6reRt:hover{width:80%;background:#dfa200;color:#fff}.SideBarMenuElement_img__zx2dJ{width:50px;margin-right:8px}.SideBarMenuElement_itemSelected__13TR0{background:#dfa200;color:#fff}

.LKAdsense_adContainer__25P-K{background-color:#dceeff;max-height:200px;width:calc(100% - 32px);max-width:calc(100% - 32px);margin:8px 16px}

.SidebarReduced_sidebar__2w-24{position:absolute;width:100%;background-color:white}.SidebarReduced_sidebarClosed__J4Les{display:none}

.MainPage_container__8QeQr{display:flex;flex-direction:row}.MainPage_rightContainer__2ZzQO{flex:1 1}

.LKTextArea_textarea__2SqUl{max-width:calc(100% - 8px);min-width:calc(100% - 8px);min-height:200px;background:transparent;border:1px solid #0a2f54;border-radius:2px;padding:4px}

.LKButton_button__1wL6e{width:200px;height:50px;border:none;background:#dfa200;color:#fff;border-radius:2px;cursor:pointer;box-shadow:rgba(0,0,0,0.1) 0px 4px 12px;display:flex;align-items:center;justify-content:center;font-size:17px;font-weight:600;margin:4px}

.LKWordCounter_container__CzEE9{height:20px;width:100%;background-color:#e0e0de;border-radius:8px;margin:8px}.LKWordCounter_filler__3TJrM{height:100%;background-color:#000000;border-radius:inherit;text-align:right;transition:width 0.2s ease-in-out;max-width:100%}.LKWordCounter_fillerFull__2jgYC{background-color:#970000}.LKWordCounter_label__23q_H{padding:5px;color:white;font-weight:bold}

.LKResultContainer_container__1EraQ{background-color:#ededed;padding:8px;display:flex;flex-direction:column;width:calc(100% - 16px);border:1px solid #dfa200;margin:8px}.LKResultContainer_titleContainer__3t3Ho{display:flex;flex-direction:row;height:50px;align-items:center}.LKResultContainer_title__37EdU{margin-left:8px}

.LKTable_cell__39Cd4{text-align:center}

table{border-collapse:collapse;background:#fff}th,td{border:1px solid #000;padding:0.75rem;text-align:left}th{font-weight:bold;white-space:nowrap;background:#0a2f54;color:#fff}tr:first-of-type th:not(:last-child){border-right-color:transparent}.responsiveTable{width:100%}.responsiveTable td .tdBefore{display:none}@media screen and (max-width: 40em){.responsiveTable table,.responsiveTable thead,.responsiveTable tbody,.responsiveTable th,.responsiveTable td,.responsiveTable tr{display:block}.responsiveTable thead tr{position:absolute;top:-9999px;left:-9999px;border-bottom:2px solid #333}.responsiveTable tbody tr{border:1px solid #000;padding:0.25em}.responsiveTable td.pivoted{border:none !important;position:relative;padding-left:calc(50% + 10px) !important;text-align:left !important;white-space:pre-wrap;overflow-wrap:break-word}.responsiveTable td .tdBefore{position:absolute;display:block;left:1rem;width:calc(50% - 20px);white-space:pre-wrap;overflow-wrap:break-word;text-align:left !important;font-weight:600}}

.AnalysisPage_container__2Iji4{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.AnalysisPage_textAreaContainer__1lLyN{width:80%}.AnalysisPage_buttonsContainer__2bh6h{width:100%;margin:24px;display:flex;justify-content:flex-end}.AnalysisPage_tableContainer__2DnFB{flex:1 1;margin:8px}

.Header_logo__3nkIa img{width:200px}.Header_menuBig__34fhI{width:80%;height:100%;display:flex;justify-content:flex-end}.Header_links__1wJ6a{height:100%;display:flex;align-items:center;margin-right:20px;font-family:"Lato", sans-serif;font-weight:500}.Header_links__1wJ6a li{list-style-type:none}.Header_iconMenu__362Fc{padding:8px;background:#fff;margin:24px;border-radius:8px;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none}.Header_iconMenu__362Fc:hover{background:#dfa200}.Header_iconMenuSelected__3uTzW{background:#dfa200}.Header_navigationBar__1J4N1{width:100%;height:80px;background:#0a2f54;display:flex;justify-content:space-between;align-items:center}

.MenuButton_highlighted__1hcXL{background:#dfa200}.MenuButton_normal__qsETn{background:white}.MenuButton_menuButton__3UILM{color:#000;font-size:20px;border-radius:1px;padding:6px;margin-right:8px;text-align:center}.MenuButton_menuButton__3UILM a{text-decoration:none;color:#000;font-size:20px;font-weight:500}

.LanguageButton_highlighted__3Athx{background:var(--amarillo)}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.HeaderReduced_logo__1NxL6 img{width:200px;max-height:80px;object-fit:cover}.HeaderReduced_logo__1NxL6{display:flex;align-items:center}.HeaderReduced_iconMenu__3zHvW{padding:8px;background:#fff;margin:16px;border-radius:8px;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;height:34px}.HeaderReduced_iconMenu__3zHvW:hover{background:#dfa200}.HeaderReduced_iconMenuSelected__3jotb{background:#dfa200}.HeaderReduced_navigationBar__2XSPi{flex-direction:column;width:100%;height:160px;background:#0a2f54;display:flex;align-items:center}.HeaderReduced_topElement__3e8p9{display:flex;justify-content:space-between;width:100%;flex:1 1}.HeaderReduced_bottomElement__kbZvi{display:flex;justify-content:flex-end;width:100%;flex:1 1}.HeaderReduced_bottomElementInnerContainer__1zvRE{list-style-type:none;display:flex;align-items:center;justify-content:flex-end;margin-right:8px}

.ConjugatorPage_container__2HJDH{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.ConjugatorPage_textAreaContainer__xz6mj{width:80%}.ConjugatorPage_buttonsContainer__3GA5t{width:100%;margin:24px;display:flex;justify-content:flex-end}

.EntityRecognizerPage_container__GpkTR{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.EntityRecognizerPage_textAreaContainer__2KOMY{width:80%}.EntityRecognizerPage_buttonsContainer__1vrZ0{width:100%;margin:24px;display:flex;justify-content:flex-end}

.LKTagCloud_tags__1MAAL{display:flex;flex-wrap:wrap;justify-content:center;margin:auto;padding:2rem 0 1rem;list-style:none}.LKTagCloud_tag__3-Xnc{display:flex;align-items:center;margin:0.1rem 0.1rem;font-weight:700;text-transform:uppercase}.LKTagCloud_colorBase__3lII4{color:#0a2f54}.LKTagCloud_colorAccent__1pU2Y{color:#dfa200}

.KeyWordExtractorPage_container__3Pw-x{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.KeyWordExtractorPage_textAreaContainer___sEOl{width:80%}.KeyWordExtractorPage_buttonsContainer__1RBjR{width:100%;margin:24px;display:flex;justify-content:flex-end}

.KeyWordPage_container__3qX5k{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.KeyWordPage_textAreaContainer__1Ve0j{width:80%}.KeyWordPage_buttonsContainer__wCBpV{width:100%;margin:24px;display:flex;justify-content:flex-end}.KeyWordPage_tableContainer__4r_z_{flex:1 1;margin:8px}

.LanguageIdentifierPage_container__3F5o0{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.LanguageIdentifierPage_textAreaContainer__KJAkb{width:80%}.LanguageIdentifierPage_buttonsContainer__2JlIQ{width:100%;margin:24px;display:flex;justify-content:flex-end}.LanguageIdentifierPage_tableContainer__2gnlB{display:flex;justify-content:center;align-items:center}

.MorphosyntacticTaggerPage_container__3KPK1{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.MorphosyntacticTaggerPage_textAreaContainer__2kfN_{width:80%}.MorphosyntacticTaggerPage_buttonsContainer__OC06I{width:100%;margin:24px;display:flex;justify-content:flex-end}.MorphosyntacticTaggerPage_tableContainer__1fT5H{flex:1 1;margin:8px}

.MultiWordExtractorPage_container__3Javm{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.MultiWordExtractorPage_textAreaContainer__13kZW{width:80%}.MultiWordExtractorPage_buttonsContainer__2F2wu{width:100%;margin:24px;display:flex;justify-content:flex-end}.MultiWordExtractorPage_tableContainer__2Qg9i{flex:1 1;margin:8px}

.SentimentAnalyzerPage_container__Puv5T{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.SentimentAnalyzerPage_textAreaContainer__1QENE{width:80%}.SentimentAnalyzerPage_buttonsContainer__zAth3{width:100%;margin:24px;display:flex;justify-content:flex-end}.SentimentAnalyzerPage_tableContainer__14t3F{flex:1 1;margin:8px}

.SummaryPage_container__25iHs{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.SummaryPage_textAreaContainer__2nsCR{width:80%}.SummaryPage_buttonsContainer__2wMdp{width:100%;margin:24px;display:flex;justify-content:flex-end}.SummaryPage_numberContainer__33qVf{display:flex;flex-direction:row;padding:24px;align-self:flex-start}

.LKNumberSelector_numericInput__3b_gd{margin:8px;padding:8px}

.SuperCorrector_container__17XvA{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.SuperCorrector_textAreaContainer__1RQ_3{width:80%}.SuperCorrector_buttonsContainer__3w2vb{width:100%;margin:24px;display:flex;justify-content:flex-end}

.SyntacticAnalyzer_container__3cg0h{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.SyntacticAnalyzer_textAreaContainer__28ybo{width:80%}.SyntacticAnalyzer_buttonsContainer__pz1eX{width:100%;margin:24px;display:flex;justify-content:flex-end}.SyntacticAnalyzer_tableContainer__3xCQ5{flex:1 1;margin:8px}

.TripletExtractorPage_container__1bLcx{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.TripletExtractorPage_textAreaContainer__ULA0T{width:80%}.TripletExtractorPage_buttonsContainer__1YKgc{width:100%;margin:24px;display:flex;justify-content:flex-end}.TripletExtractorPage_tableContainer__QyDMB{flex:1 1;margin:8px}

.WordFrequencyPage_container__3RQ9R{display:flex;flex:1 1;align-items:center;flex-direction:column;padding-right:24px;padding-left:24px;padding-top:48px}.WordFrequencyPage_textAreaContainer__hfe9a{width:80%}.WordFrequencyPage_buttonsContainer__OmzYv{width:100%;margin:24px;display:flex;justify-content:flex-end}

.LKParagraph_container__3waM8{display:flex;flex-direction:column;text-align:justify}

.AboutPage_container__2Nkik{display:flex;flex:1 1;flex-direction:column;padding:24px}.AboutPage_bannerImage__3le1R{max-height:200px}.AboutPage_servicesDescriptionContainer__1k6cA{display:flex;flex-direction:row;flex-wrap:wrap}.AboutPage_servicesDescriptionColumnContainer__257tk{display:flex;flex-direction:column;max-width:calc(25% - 32px);margin:16px}

.LegalPage_container__3LDTa{display:flex;flex:1 1;flex-direction:column;padding:24px}

