@import "../../_theme.scss";

.container {
  background-color: $bg-grey;
  padding: 8px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  border: 1px solid $accent-orange;
  margin: 8px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.title {
  margin-left: 8px;
}
