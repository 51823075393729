@import "../../_theme.scss";

.logo img {
  width: 200px;
  max-height: 80px;
  object-fit: cover;
}

.logo {
  display: flex;
  align-items: center;
}

.iconMenu {
  padding: 8px;
  background: $white;
  margin: 16px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  height: 34px;
}

.iconMenu:hover {
  background: $accent-orange;
}

.iconMenuSelected {
  background: $accent-orange;
}

.navigationBar {
  flex-direction: column;
  width: 100%;
  height: 160px;
  background: $bg-blue;
  display: flex;
  align-items: center;
}

.topElement {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.bottomElement {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
}

.bottomElementInnerContainer {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}
