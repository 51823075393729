.container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 48px;
}

.textAreaContainer {
  width: 80%;
}

.buttonsContainer {
  width: 100%;
  margin: 24px;
  display: flex;
  justify-content: flex-end;
}

.numberContainer {
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-self: flex-start;
}
