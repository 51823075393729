@import "../../_theme.scss";

.button {
  width: 200px;
  height: 50px;
  border: none;
  background: $accent-orange;
  color: $white;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 600;
  margin: 4px;
}
