.highlighted {
  background: var(--amarillo);
}

// .menuButton {
//   text-decoration: none;
//   color: var(--negro);
//   font-size: 16px;
//   border-radius: 5px;
//   padding: 4px;
//   padding-top: 4px;
//   margin-right: 8px;
//   background: white;
//   text-align: center;
// }

// .menuButton a {
//   text-decoration: none;
//   color: var(--negro);
//   font-size: 16px;
//   border-radius: 5px;
//   padding: 4px;
//   padding-top: 4px;
//   margin-right: 8px;
// }
