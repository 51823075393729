@import "../../_theme.scss";

.logo {
  img {
    width: 200px;
  }
}

.menuBig {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.links {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  li {
    list-style-type: none;
  }
}

.iconMenu {
  padding: 8px;
  background: $white;
  margin: 24px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: $accent-orange;
  }
}

.iconMenuSelected {
  background: $accent-orange;
}

.navigationBar {
  width: 100%;
  height: 80px;
  background: $bg-blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
